import React, { useEffect, useState } from "react";
import { CoachCard } from "src/components/interfaces/CoachCard";
import "./ResultCoachCard.css";
import { PageLayout } from "src/components/page-layout";
import { useGlobal } from "src/contexts/GlobalContext";
import { useHistory } from "react-router-dom";
import {
  Competition,
  Penalty,
  ResultadoCoreografia,
  ResultadosCC,
  ResultadosElementos,
} from "src/components/interfaces/Competition";
const ResultCoachCard: React.FC = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  const [competition, setCompetition] = useState<Competition>({
    Nome: "",
    Data: "",
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    Limite_inscricao: "",
    StartList: [],
    _id: "",
    Ordem: [],
    Figuras: [],
    JuizesCoachCard: undefined,
    JuizesFiguras: undefined,
  });
  const [resultCoachCardData, setResultCoachCardData] = useState<ResultadosCC>({
    _id: "",
    Categoria: "",
    Esquema: "",
    Clube: "",
    Atletas: [],
    Reservas: [],
    Resultados_el: [],
    Total_el: 0,
    Erros: { Simples: 0, Maior: 0, Obvio: 0 },
    TotalErros: 0,
    Penalty: { Value: 0.5, Name: "Penalizações", Selected: 0 },
    Resultado_Coreografia: [],
    Total_impress: 0,
    Valor_esquema: 0,
    Extra: false,
    Penalties: [],
    Valor_total: 0,
    ResultFig: [],
  });
  const getCoeficiente = (esquema: string, elemento: string) => {
    if (esquema.includes("LIVRE") || esquema === "COMBINADO") {
      return 0.5;
    }
    switch (elemento) {
      case "HIB":
        return 0.4;
      case "TRE":
        return 0.8;
      case "ACR":
        return 0.7;
      case "TRA":
        return 0;
    }
    return 1;
  };

  // const history = useHistory();
  // if (!_id || _id === "") {
  //   console.log("aaa")
  //   alert("Coach Card inválido");
  //   history.push("/listCoachCard");
  // }
  useEffect(() => {
    console.log(_id);
    if (_id && _id !== "") {
      fetch(`${url}/coachCard/${_id}`)
        .then((response) => response.json())
        .then((data: CoachCard) => {
          fetch(`${url}/competitions/${data.Competicao}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((competitionData: Competition) => {
              if (data) {
                const comp = competitionData;
                setCompetition(comp);
                console.log(comp.Resultados.ResultadosCC);
                const cc = comp.Resultados.ResultadosCC.filter((c) => {
                  return c._id?.toString() === _id?.toString();
                });
                if (cc.length > 0) {
                  setResultCoachCardData(cc[0]);
                } else {
                  let elem: ResultadosElementos[] = [];
                  data.Elementos.filter((e) => e.Elemento !== "TRA").forEach(
                    (x, i) => {
                      let notaBase = x.Nota_base;
                      if (
                        data.Esquema.includes("LIVRE") ||
                        data.Esquema === "COMBINADO"
                      ) {
                        if (x.Elemento === "ACR" && x.Grupo === "Pair") {
                          notaBase = 0.1;
                        }
                      }
                      let e: ResultadosElementos = {
                        Elemento: x.Elemento,
                        Grupo: x.Grupo,
                        BM: notaBase,
                        DD: x.Total,
                        FP: "",
                        FP_val: data.Categoria === "MAS" ? x.Total : 0,
                        J1: 0,
                        J2: 0,
                        J3: 0,
                        J4: 0,
                        J5: 0,
                        J6: 0,
                        J7: 0,
                        Media: 0,
                        CD: getCoeficiente(data.Esquema, x.Elemento),
                        Pontos: 0,
                      };
                      elem.push(e);
                    }
                  );
                  const impressArt: ResultadoCoreografia = {
                    Name: "Impressão Artistica",
                    FP_val: 1, //TODO
                    J1: 0,
                    J2: 0,
                    J3: 0,
                    J4: 0,
                    J5: 0,
                    J6: 0,
                    J7: 0,
                    Media: 0,
                    Pontos: 0,
                  };
                  let fp_value: number;
                  switch (data.Categoria) {
                    case "INF":
                      fp_value = 1.6;
                      break;
                    case "JUV":
                      if (
                        data.Esquema === "DUETO MISTO LIVRE" ||
                        data.Esquema === "DUETO LIVRE"
                      ) {
                        fp_value = 1.2;
                      } else {
                        fp_value = 2;
                      }
                      break;
                    case "JUN":
                    case "ABS":
                      if (
                        data.Esquema === "DUETO MISTO TECNICO" ||
                        data.Esquema === "DUETO TECNICO"
                      ) {
                        fp_value = 1.8;
                      } else if (
                        data.Esquema === "DUETO MISTO LIVRE" ||
                        data.Esquema === "DUETO LIVRE"
                      ) {
                        fp_value = 1.4;
                      } else if (data.Esquema === "EQUIPA LIVRE") {
                        fp_value = 2.4;
                      } else if (data.Esquema === "EQUIPA TECNICA") {
                        fp_value = 1.8;
                      } else {
                        fp_value = 1.2;
                      }
                      break;
                    case "MAS":
                      fp_value = 1.8;
                      break;
                    default:
                      fp_value = 1.8;
                      break;
                  }
                  const coreo: ResultadoCoreografia = {
                    Name: "Coreografia & Musicalidade",
                    FP_val: fp_value,
                    J1: 0,
                    J2: 0,
                    J3: 0,
                    J4: 0,
                    J5: 0,
                    J6: 0,
                    J7: 0,
                    Media: 0,
                    Pontos: 0,
                  };
                  const desemp: ResultadoCoreografia = {
                    Name: "Desempenho",
                    FP_val: 1,
                    J1: 0,
                    J2: 0,
                    J3: 0,
                    J4: 0,
                    J5: 0,
                    J6: 0,
                    J7: 0,
                    Media: 0,
                    Pontos: 0,
                  };
                  const tra: ResultadoCoreografia = {
                    Name: "Transições",
                    FP_val: 1,
                    J1: 0,
                    J2: 0,
                    J3: 0,
                    J4: 0,
                    J5: 0,
                    J6: 0,
                    J7: 0,
                    Media: 0,
                    Pontos: 0,
                  };
                  let impress: ResultadoCoreografia[];
                  if (data.Categoria === "MAS") impress = [impressArt];
                  else impress = [coreo, desemp, tra];
                  let penalties: Penalty[] = [
                    { Name: "Número de elementos", Value: 0.5, Selected: 0 },
                    { Name: "Desfile", Value: 1, Selected: 0 },
                    { Name: "Tempo do Cais", Value: 1, Selected: 0 },
                    { Name: "Tempo do Esquema", Value: 1, Selected: 0 },
                    { Name: "AS18.3.4", Value: 1, Selected: 0 },
                    { Name: "AS18.3.5", Value: 2, Selected: 0 },
                    { Name: "AS18.3.6", Value: 2, Selected: 0 },
                    { Name: "AS18.3.7", Value: 2, Selected: 0 },
                    { Name: "AS18.3.8", Value: 2, Selected: 0 },
                    { Name: "AS18.5.1", Value: 2, Selected: 0 },
                  ];
                  let resultFig: { licenca: number; valor: number }[] = [];
                  if (data.Categoria === "INF" || data.Categoria === "JUV") {
                    data.Atletas.forEach((a) => {
                      const atleta = comp.Resultados.ResultadosFiguras.find(
                        (at) => at.Atleta.Licenca === a.Licenca
                      );
                      resultFig.push({
                        licenca: a.Licenca,
                        valor: atleta?.Pontos ?? 0,
                      });
                    });
                  }
                  console.log(resultFig);
                  let result: ResultadosCC = {
                    _id: _id ?? "",
                    Categoria: data.Categoria,
                    Esquema: data.Esquema,
                    Clube: data.Clube,
                    Atletas: data.Atletas,
                    Reservas: data.Reservas,
                    Resultados_el: elem,
                    Total_el: 0,
                    Erros: { Simples: 0, Maior: 0, Obvio: 0 },
                    TotalErros: 0,
                    Penalty: { Value: 0.5, Name: "Penalizações", Selected: 0 },
                    Resultado_Coreografia: impress,
                    Total_impress: 0,
                    Valor_esquema: 0,
                    Extra: false,
                    Penalties: penalties,
                    Valor_total: 0,
                    ResultFig: resultFig,
                  };
                  setResultCoachCardData(result);
                }
              }
            });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();

  const getAverage = (index: number, isImpr: boolean = false): number => {
    let values: any[] = [];
    if (!isImpr) {
      values = [
        resultCoachCardData.Resultados_el[index].J1,
        resultCoachCardData.Resultados_el[index].J2,
        resultCoachCardData.Resultados_el[index].J3,
        resultCoachCardData.Resultados_el[index].J4,
        resultCoachCardData.Resultados_el[index].J5,
      ];
      if (competition.JuizesCoachCard && +competition.JuizesCoachCard >= 6) {
        values.push(resultCoachCardData.Resultados_el[index].J6);
      }
      if (competition.JuizesCoachCard && +competition.JuizesCoachCard === 7) {
        values.push(resultCoachCardData.Resultados_el[index].J7);
      }
    } else {
      values = [
        resultCoachCardData.Resultado_Coreografia[index].J1,
        resultCoachCardData.Resultado_Coreografia[index].J2,
        resultCoachCardData.Resultado_Coreografia[index].J3,
        resultCoachCardData.Resultado_Coreografia[index].J4,
        resultCoachCardData.Resultado_Coreografia[index].J5,
      ];
      if (competition.JuizesCoachCard && +competition.JuizesCoachCard >= 6) {
        values.push(resultCoachCardData.Resultado_Coreografia[index].J6);
      }
      if (competition.JuizesCoachCard && +competition.JuizesCoachCard === 7) {
        values.push(resultCoachCardData.Resultado_Coreografia[index].J7);
      }
    }

    let sum = 0;
    let count = 0;
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    values.forEach((value) => {
      sum += value;
      count++;

      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    });

    const average = (sum - min - max) / (count - 2);

    return +average.toFixed(4);
  };
  const getPontuation = (index: number) => {
    const el = resultCoachCardData.Resultados_el[index];
    return +(el.Media * el.FP_val * el.CD).toFixed(4);
  };
  const getPontuationImpress = (index: number) => {
    return +(
      resultCoachCardData.Resultado_Coreografia[index].FP_val *
      3 *
      resultCoachCardData.Resultado_Coreografia[index].Media
    ).toFixed(4);
  };
  resultCoachCardData.Total_el = +resultCoachCardData.Resultados_el.reduce(
    (sum, current) => sum + current.Pontos,
    0
  ).toFixed(4);
  resultCoachCardData.Total_impress =
    +resultCoachCardData.Resultado_Coreografia.reduce(
      (sum, current) => sum + current.Pontos,
      0
    ).toFixed(4);
  resultCoachCardData.Valor_esquema = +(
    (resultCoachCardData.Total_el - resultCoachCardData.TotalErros > 0
      ? resultCoachCardData.Total_el - resultCoachCardData.TotalErros
      : 0) -
    resultCoachCardData.Penalty.Value * resultCoachCardData.Penalty.Selected +
    resultCoachCardData.Total_impress
  ).toFixed(4);
  const uniqueNames = Array.from(
    new Set(resultCoachCardData.Penalties.map((penalty) => penalty.Name))
  );
  resultCoachCardData.Valor_total =
    resultCoachCardData.Valor_esquema -
    +resultCoachCardData.Penalties.reduce(
      (sum, current) => sum + current.Selected * current.Value,
      0
    ).toFixed(4) +
    (resultCoachCardData.ResultFig
      ? +resultCoachCardData.ResultFig.reduce(
          (sum, current) => sum + current.valor,
          0
        ).toFixed(4)
      : 0);

  const saveResultCoachCard = () => {
    fetch(`${url}/competitions/${_id}`)
      .then((response) => response.json())
      .then((competitionData: Competition) => {
        const comp = competitionData;
        comp.Resultados.ResultadosCC = comp.Resultados.ResultadosCC.filter(
          (c) => resultCoachCardData._id !== c._id
        );
        comp.Resultados.ResultadosCC.push(resultCoachCardData);
        return comp;
      })
      .then((comp: Competition) => deleteAndCreateComp(comp))
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteAndCreateComp = (comp: Competition) => {
    const id = comp._id;

    delete comp["_id"];
    const json = JSON.stringify(comp);
    fetch(`${url}/competitions/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: json,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCompetition({
          ...comp,
          _id: data.insertedId,
        });
        alert("Resultados Coach Card gravado com sucesso");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <PageLayout>
      <div>
        <h2>Resultados</h2>
        <div className="form-container">
          <div className="form-group">
            <label>Categoria:</label>
            <input type="text" value={resultCoachCardData.Categoria} disabled />
          </div>
          <div className="form-group">
            <label>Prova/Esquema:</label>
            <input type="text" value={resultCoachCardData.Esquema} disabled />
          </div>
          <div className="form-group">
            <label>Clube:</label>
            <input type="text" value={resultCoachCardData.Clube} disabled />
          </div>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={resultCoachCardData.Extra}
              onChange={() =>
                setResultCoachCardData({
                  ...resultCoachCardData,
                  Extra: !resultCoachCardData.Extra,
                })
              }
            />
            Extra-Competição
          </label>
        </div>
        <div>
          {resultCoachCardData.Atletas.length > 0 && (
            <div>
              <h3>Atletas:</h3>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Licenca</th>
                      <th>Nome</th>
                      <th>Ano</th>
                      <th
                        hidden={
                          resultCoachCardData.Categoria !== "INF" &&
                          resultCoachCardData.Categoria !== "JUV"
                        }
                      >
                        Valor Figuras
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultCoachCardData.Atletas.map((atleta, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="number"
                            value={atleta.Licenca}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={atleta.Nome}
                            style={{ width: "250px" }}
                            disabled
                          />
                        </td>
                        <td>
                          <input type="number" value={atleta.Ano} disabled />
                        </td>
                        <td
                          hidden={
                            resultCoachCardData.Categoria !== "INF" &&
                            resultCoachCardData.Categoria !== "JUV"
                          }
                        >
                          <input
                            type="number"
                            value={
                              resultCoachCardData.ResultFig?.find(
                                (a) => a.licenca === atleta.Licenca
                              )?.valor
                            }
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div>
          {resultCoachCardData.Reservas.length > 0 && (
            <div>
              <h3>Reservas:</h3>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Licenca</th>
                      <th>Nome</th>
                      <th>Ano</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultCoachCardData.Reservas.map((atleta, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="number"
                            value={atleta.Licenca}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={atleta.Nome}
                            style={{ width: "250px" }}
                            disabled
                          />
                        </td>
                        <td>
                          <input type="number" value={atleta.Ano} disabled />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {resultCoachCardData.Resultados_el.length > 0 && (
          <>
            <div>
              <h3>Elementos:</h3>
              <div className="table-container-1">
                <table className="table-1">
                  <thead>
                    <tr>
                      <th>Elemento</th>
                      <th>Grupos</th>
                      <th hidden={resultCoachCardData.Categoria === "MAS"}>
                        BM
                      </th>
                      <th>DD</th>
                      <th hidden={resultCoachCardData.Categoria === "MAS"}>
                        FP
                      </th>
                      <th>J1</th>
                      <th>J2</th>
                      <th>J3</th>
                      <th>J4</th>
                      <th>J5</th>
                      {competition.JuizesCoachCard &&
                        +competition.JuizesCoachCard >= 6 && <th>J6</th>}
                      {competition.JuizesCoachCard &&
                        +competition.JuizesCoachCard === 7 && <th>J7</th>}
                      <th>Média</th>
                      <th hidden={resultCoachCardData.Categoria === "MAS"}>
                        CD
                      </th>
                      <th>Pontuação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultCoachCardData.Resultados_el.map(
                      (elemento, index) => (
                        <tr key={index}>
                          <td className="content-fit">
                            <input
                              type="text"
                              value={elemento.Elemento}
                              disabled
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="text"
                              value={elemento.Grupo}
                              disabled
                            />
                          </td>
                          <td
                            hidden={resultCoachCardData.Categoria === "MAS"}
                            className="content-fit"
                          >
                            <input type="number" value={elemento.BM} disabled />
                          </td>
                          <td className="content-fit">
                            <input type="number" value={elemento.DD} disabled />
                          </td>
                          <td
                            hidden={resultCoachCardData.Categoria === "MAS"}
                            className="content-fit"
                          >
                            <select
                              value={elemento.FP}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].FP =
                                  e.target.value;
                                updatedData.Resultados_el[index].FP_val =
                                  e.target.value === "BM"
                                    ? updatedData.Resultados_el[index].BM
                                    : e.target.value === "DD"
                                    ? updatedData.Resultados_el[index].DD
                                    : 0;
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            >
                              <option value="none">Selecione</option>
                              <option key="BM" value="BM">
                                BM
                              </option>
                              <option key="DD" value="DD">
                                DD
                              </option>
                            </select>
                            <input
                              type="number"
                              value={elemento.FP_val}
                              disabled
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={elemento.J1.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].J1 =
                                  +e.target.value;
                                updatedData.Resultados_el[index].Media =
                                  getAverage(index);
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={elemento.J2.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].J2 =
                                  +e.target.value;
                                updatedData.Resultados_el[index].Media =
                                  getAverage(index);
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={elemento.J3.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].J3 =
                                  +e.target.value;
                                updatedData.Resultados_el[index].Media =
                                  getAverage(index);
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={elemento.J4.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].J4 =
                                  +e.target.value;
                                updatedData.Resultados_el[index].Media =
                                  getAverage(index);
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={elemento.J5.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultCoachCardData };
                                updatedData.Resultados_el[index].J5 =
                                  +e.target.value;
                                updatedData.Resultados_el[index].Media =
                                  getAverage(index);
                                updatedData.Resultados_el[index].Pontos =
                                  getPontuation(index);
                                setResultCoachCardData(updatedData);
                              }}
                            />
                          </td>
                          {competition.JuizesCoachCard &&
                            +competition.JuizesCoachCard >= 6 && (
                              <td className="content-fit">
                                <input
                                  type="number"
                                  min="1"
                                  max="10"
                                  style={{ width: "50px" }}
                                  value={elemento.J6.toString()}
                                  onChange={(e) => {
                                    const updatedData = {
                                      ...resultCoachCardData,
                                    };
                                    updatedData.Resultados_el[index].J6 =
                                      +e.target.value;
                                    updatedData.Resultados_el[index].Media =
                                      getAverage(index);
                                    updatedData.Resultados_el[index].Pontos =
                                      getPontuation(index);
                                    setResultCoachCardData(updatedData);
                                  }}
                                />
                              </td>
                            )}
                          {competition.JuizesCoachCard &&
                            +competition.JuizesCoachCard === 7 && (
                              <td className="content-fit">
                                <input
                                  type="number"
                                  min="1"
                                  max="10"
                                  style={{ width: "50px" }}
                                  value={elemento.J7.toString()}
                                  onChange={(e) => {
                                    const updatedData = {
                                      ...resultCoachCardData,
                                    };
                                    updatedData.Resultados_el[index].J7 =
                                      +e.target.value;
                                    updatedData.Resultados_el[index].Media =
                                      getAverage(index);
                                    updatedData.Resultados_el[index].Pontos =
                                      getPontuation(index);
                                    setResultCoachCardData(updatedData);
                                  }}
                                />
                              </td>
                            )}
                          <td className="content-fit">
                            <input
                              type="number"
                              value={elemento.Media}
                              disabled
                            />
                          </td>
                          <td
                            hidden={resultCoachCardData.Categoria === "MAS"}
                            className="content-fit"
                          >
                            {/* Tabela ao lado */}
                            <input type="number" value={elemento.CD} disabled />
                          </td>
                          <td className="content-fit">
                            <input
                              type="number"
                              value={elemento.Pontos}
                              disabled
                            />
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td hidden={resultCoachCardData.Categoria === "MAS"}></td>
                      <td></td>
                      <td hidden={resultCoachCardData.Categoria === "MAS"}></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {competition.JuizesCoachCard &&
                        +competition.JuizesCoachCard >= 6 && <td></td>}
                      {competition.JuizesCoachCard &&
                        +competition.JuizesCoachCard === 7 && <td></td>}
                      <td></td>
                      <td hidden={resultCoachCardData.Categoria === "MAS"}></td>
                      <td>{resultCoachCardData.Total_el}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              key={resultCoachCardData.Penalty.Name}
              style={{ display: "flex", float: "left" }}
            >
              <label>Penalização(0.5):</label>
              <input
                type="number"
                value={resultCoachCardData.Penalty.Selected.toString()}
                min="0"
                max="100"
                onChange={(e) => {
                  const updatedData = { ...resultCoachCardData };
                  resultCoachCardData.Penalty.Selected = +e.target.value;
                  setResultCoachCardData(updatedData);
                }}
              />
            </div>
            <div hidden={resultCoachCardData.Categoria === "MAS"}>
              <h3>Erros de Sincronização:</h3>
              <div className="table-container-1">
                <table className="table-1">
                  <thead>
                    <tr>
                      <th>Erros de Sincro</th>
                      <th>VP</th>
                      <th>Número</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="content-fit">Simples</td>
                      <td className="content-fit">0,1</td>
                      <td className="content-fit">
                        <input
                          type="number"
                          value={resultCoachCardData.Erros.Simples.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultCoachCardData };
                            updatedData.Erros.Simples = +e.target.value;
                            updatedData.TotalErros = +(
                              updatedData.Erros.Obvio * 0.5 +
                              updatedData.Erros.Simples * 0.1 +
                              updatedData.Erros.Maior * 3
                            ).toFixed(4);
                            setResultCoachCardData(updatedData);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="content-fit">Óbvio</td>
                      <td className="content-fit">0,5</td>
                      <td className="content-fit">
                        <input
                          type="number"
                          value={resultCoachCardData.Erros.Obvio.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultCoachCardData };
                            updatedData.Erros.Obvio = +e.target.value;
                            updatedData.TotalErros = +(
                              updatedData.Erros.Obvio * 0.5 +
                              updatedData.Erros.Simples * 0.1 +
                              updatedData.Erros.Maior * 3
                            ).toFixed(4);
                            setResultCoachCardData(updatedData);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="content-fit">Maior</td>
                      <td className="content-fit">3,0</td>
                      <td className="content-fit">
                        <input
                          type="number"
                          value={resultCoachCardData.Erros.Maior.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultCoachCardData };
                            updatedData.Erros.Maior = +e.target.value;
                            updatedData.TotalErros = +(
                              updatedData.Erros.Obvio * 0.5 +
                              updatedData.Erros.Simples * 0.1 +
                              updatedData.Erros.Maior * 3
                            ).toFixed(4);
                            setResultCoachCardData(updatedData);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Total Erros:</td>
                      <td>{resultCoachCardData.TotalErros}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        <h3>
          VALOR TOTAL DE ELEMENTOS:
          {resultCoachCardData.Total_el -
            resultCoachCardData.TotalErros -
            resultCoachCardData.Penalty.Value *
              resultCoachCardData.Penalty.Selected}
        </h3>
        {resultCoachCardData.Resultado_Coreografia.length > 0 && (
          <div>
            <h3>Impressão Artística:</h3>
            <div className="table-container-1">
              <table className="table-1">
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>FP</th>
                    <th style={{ width: "50px" }}>J1</th>
                    <th style={{ width: "50px" }}>J2</th>
                    <th style={{ width: "50px" }}>J3</th>
                    <th style={{ width: "50px" }}>J4</th>
                    <th style={{ width: "50px" }}>J5</th>
                    {competition.JuizesCoachCard &&
                      +competition.JuizesCoachCard >= 6 && (
                        <th style={{ width: "50px" }}>J6</th>
                      )}
                    {competition.JuizesCoachCard &&
                      +competition.JuizesCoachCard === 7 && (
                        <th style={{ width: "50px" }}>J7</th>
                      )}
                    <th>Média</th>
                    <th>Pontuação</th>
                  </tr>
                </thead>
                <tbody>
                  {resultCoachCardData.Resultado_Coreografia.map(
                    (impressao, index) => (
                      <tr key={index}>
                        <td className="content-fit">
                          <input type="text" value={impressao.Name} disabled />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            value={impressao.FP_val}
                            disabled
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            style={{ width: "50px" }}
                            value={impressao.J1.toString()}
                            onChange={(e) => {
                              const updatedData = { ...resultCoachCardData };
                              updatedData.Resultado_Coreografia[index].J1 =
                                +e.target.value;
                              updatedData.Resultado_Coreografia[index].Media =
                                getAverage(index, true);
                              updatedData.Resultado_Coreografia[index].Pontos =
                                getPontuationImpress(index);
                              setResultCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            style={{ width: "50px" }}
                            value={impressao.J2.toString()}
                            onChange={(e) => {
                              const updatedData = { ...resultCoachCardData };
                              updatedData.Resultado_Coreografia[index].J2 =
                                +e.target.value;
                              updatedData.Resultado_Coreografia[index].Media =
                                getAverage(index, true);
                              updatedData.Resultado_Coreografia[index].Pontos =
                                getPontuationImpress(index);
                              setResultCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            style={{ width: "50px" }}
                            value={impressao.J3.toString()}
                            onChange={(e) => {
                              const updatedData = { ...resultCoachCardData };
                              updatedData.Resultado_Coreografia[index].J3 =
                                +e.target.value;
                              updatedData.Resultado_Coreografia[index].Media =
                                getAverage(index, true);
                              updatedData.Resultado_Coreografia[index].Pontos =
                                getPontuationImpress(index);
                              setResultCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            style={{ width: "50px" }}
                            value={impressao.J4.toString()}
                            onChange={(e) => {
                              const updatedData = { ...resultCoachCardData };
                              updatedData.Resultado_Coreografia[index].J4 =
                                +e.target.value;
                              updatedData.Resultado_Coreografia[index].Media =
                                getAverage(index, true);
                              updatedData.Resultado_Coreografia[index].Pontos =
                                getPontuationImpress(index);
                              setResultCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            style={{ width: "50px" }}
                            value={impressao.J5.toString()}
                            onChange={(e) => {
                              const updatedData = { ...resultCoachCardData };
                              updatedData.Resultado_Coreografia[index].J5 =
                                +e.target.value;
                              updatedData.Resultado_Coreografia[index].Media =
                                getAverage(index, true);
                              updatedData.Resultado_Coreografia[index].Pontos =
                                getPontuationImpress(index);
                              setResultCoachCardData(updatedData);
                            }}
                          />
                        </td>
                        {competition.JuizesCoachCard &&
                          +competition.JuizesCoachCard >= 6 && (
                            <td className="content-fit">
                              <input
                                type="number"
                                min="1"
                                max="10"
                                style={{ width: "50px" }}
                                value={impressao.J6.toString()}
                                onChange={(e) => {
                                  const updatedData = {
                                    ...resultCoachCardData,
                                  };
                                  updatedData.Resultado_Coreografia[index].J6 =
                                    +e.target.value;
                                  updatedData.Resultado_Coreografia[
                                    index
                                  ].Media = getAverage(index, true);
                                  updatedData.Resultado_Coreografia[
                                    index
                                  ].Pontos = getPontuationImpress(index);
                                  setResultCoachCardData(updatedData);
                                }}
                              />
                            </td>
                          )}
                        {competition.JuizesCoachCard &&
                          +competition.JuizesCoachCard === 7 && (
                            <td className="content-fit">
                              <input
                                type="number"
                                min="1"
                                max="10"
                                style={{ width: "50px" }}
                                value={impressao.J7.toString()}
                                onChange={(e) => {
                                  const updatedData = {
                                    ...resultCoachCardData,
                                  };
                                  updatedData.Resultado_Coreografia[index].J7 =
                                    +e.target.value;
                                  updatedData.Resultado_Coreografia[
                                    index
                                  ].Media = getAverage(index, true);
                                  updatedData.Resultado_Coreografia[
                                    index
                                  ].Pontos = getPontuationImpress(index);
                                  setResultCoachCardData(updatedData);
                                }}
                              />
                            </td>
                          )}
                        <td className="content-fit">
                          <input
                            type="number"
                            value={impressao.Media}
                            disabled
                          />
                        </td>
                        <td className="content-fit">
                          <input
                            type="number"
                            value={impressao.Pontos}
                            disabled
                          />
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {competition.JuizesCoachCard &&
                      +competition.JuizesCoachCard >= 6 && <td></td>}
                    {competition.JuizesCoachCard &&
                      +competition.JuizesCoachCard === 7 && <td></td>}
                    <td></td>
                    <td>{resultCoachCardData.Total_impress}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <h3>VALOR DO ESQUEMA: {resultCoachCardData.Valor_esquema}</h3>
        <table className="table">
          <thead>
            <tr>
              {uniqueNames.map((name) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>
            
          </td> */}
              {uniqueNames.map((name, index) => (
                <td key={index}>
                  {resultCoachCardData.Penalties.filter(
                    (penalty) => penalty.Name === name
                  ).map((filteredPenalty, index) => (
                    <div key={index} style={{ display: "flex", float: "left" }}>
                      <input
                        hidden={filteredPenalty.Name !== "Número de elementos"}
                        type="number"
                        value={filteredPenalty.Selected.toString()}
                        min="0"
                        max="4"
                        onChange={(e) => {
                          const updatedData = { ...resultCoachCardData };
                          updatedData.Penalties.filter(
                            (p) => p.Name === filteredPenalty.Name
                          )[0].Selected = +e.target.value;
                          setResultCoachCardData(updatedData);
                        }}
                      />
                      <input
                        hidden={filteredPenalty.Name === "Número de elementos"}
                        type="checkbox"
                        checked={filteredPenalty.Selected === 1}
                        onChange={(e) => {
                          const updatedData = { ...resultCoachCardData };
                          updatedData.Penalties.filter(
                            (p) => p.Name === filteredPenalty.Name
                          )[0].Selected === 0
                            ? (updatedData.Penalties.filter(
                                (p) => p.Name === filteredPenalty.Name
                              )[0].Selected = 1)
                            : (updatedData.Penalties.filter(
                                (p) => p.Name === filteredPenalty.Name
                              )[0].Selected = 0);
                          setResultCoachCardData(updatedData);
                          console.log(updatedData);
                        }}
                      />
                      <span>{filteredPenalty.Value}</span>
                    </div>
                  ))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <h3
          hidden={
            resultCoachCardData.Categoria !== "INF" &&
            resultCoachCardData.Categoria !== "JUV"
          }
        >
          VALOR TOTAL FIGURAS:
          {resultCoachCardData.ResultFig
            ? +resultCoachCardData.ResultFig.reduce(
                (sum, current) => sum + current.valor,
                0
              ).toFixed(4)
            : 0}
        </h3>
        <h3>VALOR DO TOTAL: {resultCoachCardData.Valor_total.toFixed(4)}</h3>
        <button onClick={saveResultCoachCard}>Gravar Coach Card</button>
      </div>
    </PageLayout>
  );
};

export default ResultCoachCard;
